var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":{
    text: 'Save',
    key: 'save',
    disabled: _vm.disablePrimaryToolbar,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[(_vm.initialized)?_c('div',[(_vm.$getCurrentUser && _vm.$getCurrentUser.role == 'admin')?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.menuItems,"selectedTabKey":_vm.selectedTabKey},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.resourceNotFound && !_vm.showActivities)?_c('div',[_c('form-builder',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],ref:"formElement",attrs:{"schemas":_vm.formFields,"formValues":_vm.editing,"dynamicOptions":{
          companies: _vm.companyOptions,
          formTypes: _vm.formTypesOptions,
          appliesTo: _vm.appliesToOptions
        }},on:{"validationChanged":function($event){_vm.validationData = $event},"formFieldChanged":_vm.formFieldChangedHandler}}),(_vm.editing)?_c('div',{staticClass:"flex justify-start",class:[
          Object.keys(_vm.updatedFields).length > 0 ? 'justify-between' : ''
        ]},[_c('IAButton',{staticClass:"text-red-500",attrs:{"click":_vm.deleteCallback,"title":_vm.__getTextUcFirst('simple', 'deleteEndorsement'),"type":"text"}})],1):_vm._e(),_c('ModalMessage',{attrs:{"name":"endorsementSingleDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Confirm deletion","description":"This will delete the endorsement. Continue?","showClose":true,"buttons":[
          {
            text: `Okay`,
            classList: [''],
            click: () => _vm.deleteOneEndorsement()
          },
          {
            text: `Cancel`,
            classList: ['bg-red-700'],
            click: () => _vm.$modal.hide('endorsementSingleDeleteModal')
          }
        ]}}),_c('ModalMessage',{attrs:{"name":"endorsementSingleEditModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":_vm.__getText('global', 'willSaveChangesTitle'),"description":_vm.__getText('global', 'willSaveChanges'),"showClose":true,"buttons":[
          {
            text: `Ok`,
            classList: [''],
            click: () => _vm.submit()
          },
          {
            text: `Cancel`,
            classList: [''],
            click: () => _vm.$modal.hide('endorsementSingleEditModal')
          }
        ]}})],1):(_vm.showActivities)?_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}],null,false,3991501741)}):_c('ResourceNotFound',{attrs:{"title":_vm.resourceNotFoundMsg,"actionButtonTitle":"Back To Endorsements","actionRedirectPath":"/admin/endorsements"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }