
import { FormBlock } from "@/components/FormBuilder/types";
import { endorsementsForm } from "@/forms/admin/endorsements";
import { objectDifference } from "@/helpers";
import {
  endorsementMapActions,
  endorsementMapGetters,
  endorsementMapMutations,
  endorsementMapState
} from "@/store/modules/endorsements";
import Vue from "vue";
import * as types from "@/store/mutation-types";
import { cloneDeep } from "lodash";
import {
  companyLineOfBusiness,
  FormsByPolicyType
} from "@/helpers/selectOptions";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IEditMortgage {
  errorMessage: string;
  successMessage: string;
  initialized: boolean;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  validationData: any;
}
export default Vue.extend({
  name: "EditEndorsement",
  mixins: [ActivitiesMixin],
  data(): IEditMortgage {
    return {
      errorMessage: "",
      successMessage: "",
      initialized: false,
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The endorsement you are looking for cannot be found.",
      validationData: {
        formIsValid: false
      }
    };
  },
  created() {
    this.getEndorsement(this.endorsementId)
      .then((values: any) => {
        if (!values) {
          this.errorMessage = "Error retrieving endorsement data";
          this.resourceNotFound = true;
        }
        this.SET_EDIT(values.data);
        this.initialized = true;
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.initialized = true;
        this.$bugSnagClient.notify(error);
      });
  },
  computed: {
    ...endorsementMapGetters(["getEndorsementById", "editingEndorsement"]),
    ...endorsementMapState(["editing", "makingApiRequest"]),
    endorsementId(): string {
      return this.$route.params.endorsementId;
    },
    endorsement(): any {
      return this.getEndorsementById(this.endorsementId);
    },
    updatedFields(): any {
      const edited = this.editingEndorsement;
      const original = this.endorsement;
      return edited && original ? objectDifference(edited, original) : {};
    },
    disablePrimaryToolbar(): boolean {
      return Object.keys(this.updatedFields).length === 0;
    },
    formFields(): FormBlock[] {
      return endorsementsForm;
    },
    formIsValid(): boolean {
      return !!this.validationData.formIsValid;
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies.map((company: any) => ({
        label: company.companyName,
        value: company.companyNumber
      }));
    },
    formTypesOptions(): { label: string; value: string }[] {
      let options = [] as any;
      for (const [key, value] of Object.entries(FormsByPolicyType)) {
        options.push({
          label: value,
          value: key
        });
      }
      return options;
    },
    appliesToOptions(): { label: string; value: string }[] {
      return companyLineOfBusiness.map((option: any) => ({
        label: option.label,
        value: option.value
      }));
    },
    queryOverride(): any {
      return {
        "data.endorsementId": this.editing?._id,
        activityType: "EndorsementActivity"
      };
    }
  },
  methods: {
    ...endorsementMapActions([
      "updateEndorsement",
      "getEndorsement",
      "deleteEndorsement"
    ]),
    ...endorsementMapMutations(["SET_EDIT"]),
    ...endorsementMapMutations({ editField: types.SET_EDIT_FIELD }),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "save":
          this.promptUpdateConfirmation();
          break;
        case "cancel":
          this.cancelCallback();
          break;
      }
    },
    async promptUpdateConfirmation(): Promise<void> {
      try {
        this.$modal.show("endorsementSingleEditModal");
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },
    async cancelCallback(): Promise<any> {
      this.$router.push("/admin/endorsements").catch(() => {});
    },
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          let fieldsUpdated = cloneDeep(this.updatedFields);
          await this.updateEndorsement({
            update: {
              ...fieldsUpdated,
              action: "update",
              endorsementIds: [this.endorsementId]
            }
          });
          this.successMessage = "Endorsement updated successfully";
          this.$router.push("/admin/endorsements").catch(() => {});
        } else {
          this.errorMessage = "Please correct the errors in the form";
        }
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async deleteCallback(): Promise<void> {
      try {
        this.$modal.show("endorsementSingleDeleteModal");
      } catch (error) {
        // do nothing
      }
    },
    async deleteOneEndorsement(): Promise<void> {
      try {
        await this.deleteEndorsement(this.endorsementId);
        this.successMessage = "Endorsement deleted successfully";
        this.$router.push("/admin/endorsements").catch(() => {});
      } catch (error) {
        this.errorMessage = error.message;
      }
    }
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  }
});
